import React, { useState, useEffect } from "react";
import { Triangle } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { TQ_URL } from "../../utility/baseUrl";
import { getRequestWithTokenWithoutData } from "../../utility/apiRequest";
import AccountModalHome from "./AccountModalHome";
import Cookies from "js-cookie";

const TopHeader = () => {
  const [config, setConfig] = useState({});
  const [userData, setUserData] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const isUserLoggedIn = () => {
    return userData !== null;
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };
  
  const openAccountModal = () => {
    setIsDropdownOpen(true);
  };
  
  const handleLoadingState = () => {
    setIsLoading(true);
    
};


  const logLocalData = () => {
    const localStorageData = localStorage.getItem("userData");
    console.log("Local Storage Data:", JSON.parse(localStorageData));
    setUserData(JSON.parse(localStorageData));
  };

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => setConfig(data))
      .catch((error) => console.error("Error loading config.json:", error));

    logLocalData();
  }, []);

  const setCookie = () => {
    const isLocalhost = window.location.hostname.includes("localhost");
    // const domain = isLocalhost ? "localhost" : ".prabalta.org";
    const domain = isLocalhost ? "localhost" : "prabalta.org";
    const path = isLocalhost ? "/" : "/prabaltatwo";

    Cookies.set("UserType", "Individual", {
      domain: domain,
      path: path,
      secure: !isLocalhost, // Use secure only in production
      sameSite: isLocalhost ? "Lax" : "None", // 'None' allows cross-site cookies, 'Lax' for local
      expires: 1, // 1-day expiry
    });

  }


  return (
    <>
      {/* Top Header */}
      <div
        className="grid grid-cols-2 md:flex text-white p-2 md:justify-between items-center px-4 md:px-10 2xl:px-20"
        style={{ backgroundColor: config.primaryColor }}
      >
        <div>
          {/* Make email clickable */}
          <a href={`mailto:${config.email}`} className="text-sm md:text-lg">
            {config.email}
          </a>
        </div>
        <div className="flex items-center space-x-4 justify-end">
          <div className="w-full space-x-6 items-center me-10 md:flex hidden">
            {config.facebookUrl && (
              <a href={`${config.facebookUrl}`} target="blank">
                <svg
                  className="w-6"
                  fill="#ffffff"
                  viewBox="0 0 22 32"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#ffffff"
                  stroke-width="0.00032"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M21.95 5.005l-3.306-.004c-3.206 0-5.277 2.124-5.277 5.415v2.495H10.05v4.515h3.317l-.004 9.575h4.641l.004-9.575h3.806l-.003-4.514h-3.803v-2.117c0-1.018.241-1.533 1.566-1.533l2.366-.001.01-4.256z"></path>
                  </g>
                </svg>
              </a>
            )}
            {config.instagramUrl && (
              <a href={`${config.instagramUrl}`} target="blank">
                <svg
                  className="w-6"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#c2adad"
                  stroke-width="0.00024000000000000003"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                      fill="#ffffff"
                    ></path>{" "}
                    <path
                      d="M18 5C17.4477 5 17 5.44772 17 6C17 6.55228 17.4477 7 18 7C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5Z"
                      fill="#ffffff"
                    ></path>{" "}
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1.65396 4.27606C1 5.55953 1 7.23969 1 10.6V13.4C1 16.7603 1 18.4405 1.65396 19.7239C2.2292 20.8529 3.14708 21.7708 4.27606 22.346C5.55953 23 7.23969 23 10.6 23H13.4C16.7603 23 18.4405 23 19.7239 22.346C20.8529 21.7708 21.7708 20.8529 22.346 19.7239C23 18.4405 23 16.7603 23 13.4V10.6C23 7.23969 23 5.55953 22.346 4.27606C21.7708 3.14708 20.8529 2.2292 19.7239 1.65396C18.4405 1 16.7603 1 13.4 1H10.6C7.23969 1 5.55953 1 4.27606 1.65396C3.14708 2.2292 2.2292 3.14708 1.65396 4.27606ZM13.4 3H10.6C8.88684 3 7.72225 3.00156 6.82208 3.0751C5.94524 3.14674 5.49684 3.27659 5.18404 3.43597C4.43139 3.81947 3.81947 4.43139 3.43597 5.18404C3.27659 5.49684 3.14674 5.94524 3.0751 6.82208C3.00156 7.72225 3 8.88684 3 10.6V13.4C3 15.1132 3.00156 16.2777 3.0751 17.1779C3.14674 18.0548 3.27659 18.5032 3.43597 18.816C3.81947 19.5686 4.43139 20.1805 5.18404 20.564C5.49684 20.7234 5.94524 20.8533 6.82208 20.9249C7.72225 20.9984 8.88684 21 10.6 21H13.4C15.1132 21 16.2777 20.9984 17.1779 20.9249C18.0548 20.8533 18.5032 20.7234 18.816 20.564C19.5686 20.1805 20.1805 19.5686 20.564 18.816C20.7234 18.5032 20.8533 18.0548 20.9249 17.1779C20.9984 16.2777 21 15.1132 21 13.4V10.6C21 8.88684 20.9984 7.72225 20.9249 6.82208C20.8533 5.94524 20.7234 5.49684 20.564 5.18404C20.1805 4.43139 19.5686 3.81947 18.816 3.43597C18.5032 3.27659 18.0548 3.14674 17.1779 3.0751C16.2777 3.00156 15.1132 3 13.4 3Z"
                      fill="#ffffff"
                    ></path>{" "}
                  </g>
                </svg>
              </a>
            )}
            {config.linkedinUrl && (
              <a href={`${config.linkedinUrl}`} target="blank">
                <svg
                  className="w-6"
                  viewBox="0 0 20.00 20.00"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke="#CCCCCC"
                    stroke-width="0.12"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <title>linkedin [#ffffff]</title>{" "}
                    <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                    <g
                      id="Page-1"
                      stroke-width="0.0002"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      {" "}
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-180.000000, -7479.000000)"
                        fill="#ffffff"
                      >
                        {" "}
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                        >
                          {" "}
                          <path
                            d="M144,7339 L140,7339 L140,7332.001 C140,7330.081 139.153,7329.01 137.634,7329.01 C135.981,7329.01 135,7330.126 135,7332.001 L135,7339 L131,7339 L131,7326 L135,7326 L135,7327.462 C135,7327.462 136.255,7325.26 139.083,7325.26 C141.912,7325.26 144,7326.986 144,7330.558 L144,7339 L144,7339 Z M126.442,7323.921 C125.093,7323.921 124,7322.819 124,7321.46 C124,7320.102 125.093,7319 126.442,7319 C127.79,7319 128.883,7320.102 128.883,7321.46 C128.884,7322.819 127.79,7323.921 126.442,7323.921 L126.442,7323.921 Z M124,7339 L129,7339 L129,7326 L124,7326 L124,7339 Z"
                            id="linkedin-[#ffffff]"
                          >
                            {" "}
                          </path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </a>
            )}

            {config.xurl && (
              <a href={config.xurl} target="_blank" rel="noopener noreferrer">
                <svg
                  className="w-6"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#ffffff"
                >
                  <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                </svg>
              </a>
            )}

            {config.threadUrl && (
              <a href={`${config.threadUrl}`} target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  shape-rendering="geometricPrecision"
                  text-rendering="geometricPrecision"
                  image-rendering="optimizeQuality"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  fill="white"
                  width="24"
                  height="24"
                  viewBox="0 0 439.999 511.429"
                  version="1.1"
                >
                  <path
                    fill-rule="nonzero"
                    d="M342.382 237.037a176 176 0 0 0-6.707-3.045c-3.947-72.737-43.692-114.379-110.428-114.805-38.505-.256-72.972 15.445-94.454 48.041l36.702 25.178c15.265-23.159 39.221-28.097 56.864-28.097q.306 0 .61.003c21.973.139 38.555 6.528 49.287 18.987 7.81 9.071 13.034 21.606 15.62 37.425-19.482-3.312-40.552-4.329-63.077-3.039-63.449 3.656-104.24 40.661-101.5 92.081 1.39 26.083 14.384 48.522 36.586 63.18 18.773 12.391 42.95 18.451 68.078 17.08 33.183-1.819 59.214-14.48 77.376-37.631 13.793-17.579 22.516-40.362 26.368-69.068 15.814 9.543 27.535 22.103 34.007 37.2 11.007 25.665 11.648 67.84-22.764 102.222-30.15 30.121-66.392 43.151-121.164 43.554-60.757-.45-106.707-19.934-136.582-57.914-27.976-35.563-42.434-86.93-42.973-152.675.539-65.745 14.997-117.113 42.973-152.675 29.875-37.979 75.824-57.464 136.581-57.914 61.197.455 107.948 20.033 138.967 58.195 15.21 18.713 26.677 42.248 34.236 69.688l43.011-11.476c-9.163-33.775-23.581-62.881-43.203-87.017C357.031 25.59 298.872.519 223.935 0h-.3C148.851.518 91.343 25.683 52.709 74.794 18.331 118.498.598 179.308.002 255.534l-.002.18.002.18c.596 76.226 18.329 137.037 52.707 180.741 38.634 49.11 96.142 74.277 170.926 74.794h.3c66.487-.462 113.352-17.868 151.96-56.442 50.511-50.463 48.991-113.717 32.342-152.548-11.944-27.847-34.716-50.464-65.855-65.402m-114.795 107.93c-27.809 1.566-56.7-10.917-58.124-37.652-1.056-19.823 14.108-41.942 59.83-44.577q7.853-.452 15.422-.45c16.609 0 32.146 1.613 46.272 4.702-5.268 65.798-36.173 76.483-63.4 77.977"
                  ></path>
                </svg>
              </a>
            )}
          </div>
          {isUserLoggedIn() && (
            // Show user-specific content when logged in
            <div className=" block">
              {isLoading ? (
                <div className="flex items-center justify-center h-auto w-full md:pr-20">
                  <Triangle
                    height="20"
                    width="20"
                    color="#ffffff"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    visible={true}
                  />
                </div>
              ) : (
                <div className="cursor-pointer" onClick={openAccountModal}>
                  <div className="w-11/12 flex items-center justify-between space-x-4 me-8">
                    <h1 className="text-xl">
                      {userData.userName.split(" ")[0]}
                    </h1>
                    {/* <img
                      src="assets/images/user.webp"
                      alt="User Avatar"
                      className="rounded-full h-8 w-8"
                    /> */}

                    {userData.imageUrl == null ? (
                      <img
                        src={
                          "https://prabalta.org/assets/images/user.webp"
                        } // or whatever your placeholder image path is
                        alt="Placeholder Avatar"
                        className="rounded-full h-8 w-8"
                      />
                    ) : (
                      <img
                        src={userData.imageUrl}
                        alt="User Avatar"
                        className="rounded-full h-8 w-8"
                      />
                    )}
                  </div>
                </div>
              )}
             
            </div>
          )}

          {!isUserLoggedIn() && (
            // Show login button when not logged in
            <Link to="/ngo-login" onClick={() => setCookie()}>
              <button className="bg-white text-blue-500 py-1 px-2 rounded login-btn ml-6">
                Login
              </button>
            </Link>
          )}
        </div>
        <AccountModalHome isOpen={isDropdownOpen} onClose={() => setIsDropdownOpen(false)}  onLoadingStateChange={handleLoadingState} />
      </div>
    </>
  );
};

export default TopHeader;