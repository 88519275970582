import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import confetti from "canvas-confetti"; // Import the confetti library
import { useNavigate } from "react-router-dom";

const Success = () => {
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      triggerGlitterConfetti();
    }, 400); // Sync with bounce animation (0.8s delay)
  }, []);

  const navigate = useNavigate();

  // Assuming donationData is stored in localStorage or state
  const donationData = JSON.parse(localStorage.getItem("donationData"));
  console.log(donationData);

  const handleConfirm = () => {
    if (!donationData) {
      navigate("/home"); // Redirect to home if donationData is null
    } else {
      // navigate("/donate"); // Redirect to donate if donationData is not null
      navigate("/donate", { state: donationData });
      localStorage.removeItem("donationData");
    }
  };

  const triggerGlitterConfetti = () => {
    confetti({
      particleCount: 200,
      spread: 120,
      origin: { x: 0.5, y: 0.5 },
      colors: ["#FFC107", "#FF5722", "#8BC34A", "#00BCD4", "#9C27B0"],
      ticks: 500,
      startVelocity: 50,
      gravity: 0.6,
    });
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen ">
      {/* Left Section */}
      <div className="md:flex-1 bg-cover bg-center relative fade-in-left verify-addr-bg">
        <div className="absolute inset-0 bg-black opacity-40"></div>
        <div className="absolute inset-0 md:flex flex-col items-center justify-center md:px-20 px-5">
          <div className="w-full">
            <div className="success-message">
              {/* <div className="text-center md:text-left">
                <img
                  src="assets/images/stars.svg"
                  alt="Logo"
                  className="h-4 mx-auto sm:mx-0"
                />
              </div> */}
              <h1 className="txt-style-5">Congratulations !</h1>

              <h1 className="txt-style-6">
                You are now a part of <b>Prabalta.</b>
              </h1>
              <p className="para-style-1 text-center">
                Explore our initiatives and join us in creating a better world
                together.
              </p>
              <div className="flex  justify-between mt-5">
                <button
                  className="text-white px-10 py-2 rounded-sm btn-style-1 ms-5"
                  onClick={handleConfirm}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
