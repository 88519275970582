import React, { useState, useEffect, forwardRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputMask from "react-input-mask";
import {
  TQ_FRONTEND_URL,
  TQ_URL,
  TQ_URL_USPROJECT,
} from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import { Triangle } from "react-loader-spinner";
import { PinInput } from "@mantine/core";
import { FaCheckCircle, FaPaperPlane } from "react-icons/fa";
import { IoIosRefreshCircle } from "react-icons/io";

// Function to remove mask from phone number
const stripMask = (value) => value.replace(/\D/g, "");

const schema = yup.object().shape({
  email: yup.string().email().required("Email is Required *"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .max(25, "Password must be at most 25 characters")
    .required("Password is Required *"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is Required *"),
  username: yup
    .string()
    .min(5, "Username must be at least 5 characters")
    .max(50, "Username must be at most 50 characters")
    .required("Username is Required *"),
  contactnumber: yup
    .string()
    .required("Contact Number is Required *")
    .transform(stripMask)
    .matches(
      /^[2-9]\d{2}[2-9]\d{2}\d{4}$/,
      "Contact number must be a valid US phone number"
    ),
  termsAndConditions: yup
    .boolean()
    .oneOf([true], "Please accept the terms and conditions"),
});

const CustomInput = forwardRef((props, ref) => (
  <InputMask {...props} inputRef={ref} />
));

const NgoCreateAccount = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState(Cookies.get("UserType"));

  const [showOtp, setShowOtp] = useState(false);
  const [pin, setPin] = useState("");
  const [timer, setTimer] = useState(0);
  const [showRefresh, setShowRefresh] = useState(false);
  const [isVerifyEmail, setIsVerifyEmail] = useState(false);
  const [showOtpButton, setShowOtpButton] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // Handle when the email input loses focus
  const handleBlur = (e) => {
    const email = e.target.value;
    console.log(email.length);
    if (emailRegex.test(email)) {
      clearErrors("email");
      setShowOtpButton(true);
    } else {
      setShowOtpButton(false);
      setError("email", {
        type: "manual", // 'manual' to manually set an error
        message: "Please enter a valid email id",
      });
      //  toast.error("Please enter valid email format");
    }
    if (email.lenght == 0) {
      setError("email", {
        type: "manual", // 'manual' to manually set an error
        message: "Please enter email id",
      });
    }
  };

  const [isPreviousMail, setIsPreviousMail] = useState();
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = async (data, event) => {
    console.log("Inside create account");
    if (isVerifyEmail == false) {
      toast.info("Please verify email id", { position: "top-right" });
      return;
    }
    setLoading(true);

    console.log(data);
    event.preventDefault();
    try {
      const response = await fetch(`${TQ_URL}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          organizationName:
            userType === "Organization" ? toInitCap(data.organizationName) : "",
          userName: toInitCap(data.username),
          email: data.email,
          name: data.username,
          contactNumber: data.contactnumber,
          userType: userType,
          password: data.confirmPassword,
        }),
      });

      const res = await response.json();

      console.log("BODY >>", res);

      if (response.status === 200) {
        if (res.data.userType === "Individual") {
          const userDetails = response.details;
          localStorage.setItem(
            "userData",
            JSON.stringify({
              userId: res.data.userId,
              userName: res.data.userName,
              email: res.data.email,
              phone: res.data.phone,
              userType: res.data.userType,
              imageUrl: res.data.imageUrl,
            })
          );

          console.log(res.addressDetails);

          localStorage.setItem(
            "userAddressDetails",
            JSON.stringify({ addressDetails: res.addressDetails })
          );

          localStorage.setItem("accessToken", res.accessToken);
        } else {
          const partnerDetails = response.details;
          localStorage.setItem(
            "partnerData",
            JSON.stringify({
              partnerId: res.data.partnerId,
              orgName: res.data.orgName,
              contactPersonName: res.data.contactPersonName,
              email: res.data.email,
              contactNumber: res.data.contactNumber,
              userType: res.data.userType,
              imageUrl: res.data.imageUrl,
              addressOne: res.data.addressOne,
              addressTwo: res.data.addressTwo,
              city: res.data.city,
              state: res.data.state,
              zipCode: res.data.zipCode,
              role: "partner",
            })
          );
          localStorage.setItem(
            "userData",
            JSON.stringify({
              userId: res.registerUserData.userId,
              userName: toInitCap(res.registerUserData.userName),
              email: res.registerUserData.email,
              phone: res.registerUserData.phone,
              userType: userType,
              imageUrl: res.registerUserData.imageUrl,
            })
          );
          localStorage.setItem("accessToken", res.accessToken);
        }
        userCreated(data.email);
        reset();
      } else if (response.status === 400 && res.error) {
        let errorMsg = "";
        for (const [key, value] of Object.entries(res.error)) {
          errorMsg += `${value} `;
        }

        console.log(errorMsg);

        toast.error(errorMsg, { position: "top-right" });
      } else {
        userNotCreated();
      }
    } catch (error) {
      console.error("Error during registration:", error);
      userNotCreated();
    } finally {
      setLoading(false);
    }
  };

  function toInitCap(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const handleSocialIconClick = (dynamicUrl) => {
  //  const frontendLink = "https://prabalta.org:8087/prabaltatwo";
     const frontendLink = "https://prabalta.org:8087/prabaltatwouat";
    console.log(dynamicUrl);
// const frontendLink = "http://localhost:8080";

    if (dynamicUrl.includes(frontendLink)) {
      window.location.href = dynamicUrl;
    } else {
      console.error("Invalid URL for redirection");
    }
  };

  const userCreated = (email) => {
    toast.success("User Created Successfully!", { position: "top-right" });

    setTimeout(() => {
      navigate(`/add-ngo-addressdetails?email=${encodeURIComponent(email)}`);
    }, 1000);
  };

  const userNotCreated = () => {
    toast.error("Oops! User Not Created", { position: "top-right" });
  };

  useEffect(() => {
    setUserType(Cookies.get("UserType"));
  }, []);

  useEffect(() => {
    let interval;
    if (showOtp && timer === 0) {
      setShowRefresh(true);
    }
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [showOtp, timer]);

  console.log("Errors:", errors);

  const googleOAuthUrl =
    TQ_URL + "/oauth2/authorize/google?redirect_uri=" + TQ_FRONTEND_URL;

  const facebookOAuthUrl =
    TQ_URL + "/oauth2/authorize/facebook?redirect_uri=" + TQ_FRONTEND_URL;

  const microsoftOAuthUrl =
    TQ_URL + "/oauth2/authorize/microsoft?redirect_uri=" + TQ_FRONTEND_URL;

  const otpFunction = async (event) => {
    event.preventDefault();
    if (!email) {
      toast.error("Please enter a valid email address", {
        position: "top-right",
      });
      return; // Don't proceed if email is invalid
    }
    if (timer > 0 && email === isPreviousMail) {
      toast.info("You have already requested OTP for this email", {
        position: "top-right",
      });
      return; // Don't proceed if emails are the same
    }
    const url =
      userType == "Individual"
        ? `${TQ_URL_USPROJECT}/sendEmailotp`
        : `${TQ_URL}/sendEmailotpPartner`;

    let request = {
      email: email,
      partnerId: "1",
      flag: "SignUp",
    };
    console.log("Watch email->", email);
    setIsVerifyEmail(false);
    if (email.includes("@")) {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });
      console.log(response);
      if (response.status == 200) {
        setPin("");
        setShowOtp(true);
        setTimer(90);
        setShowRefresh(false);
        toast.success("Otp is send on email", { position: "top-right" });
        setIsPreviousMail(email);
      } else if (response.status == 400)
        toast.info("This email is used, please use different email", {
          position: "top-right",
        });
    } else {
      setShowOtp(false);
      setTimer(0);
      setPin("");
      setShowRefresh(false);
      setIsVerifyEmail(false);
    }
  };

  const email = watch("email");

  const handleChange = async (value) => {
    setPin(value);
    const otpValue = value;
    if (otpValue.length < 4) {
      return;
    }
    const url =
      userType == "Individual"
        ? `${TQ_URL_USPROJECT}/verifyOtp`
        : `${TQ_URL}/verifyOtpPartner`;

    console.log("value -------->", value);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          otp: parseInt(otpValue),
          partnerId: 1,
        }),
      });
      const data = await response.json();
      console.log(response);
      if (data) {
        if (data.status === 200) {
          toast.success("OTP Verified Successfully!");
          setIsVerifyEmail(true);
          setShowOtp(false);
          setTimer(0);
          setPin("");
          setShowRefresh(false);
        } else if (data.status === 401) {
          toast.error("Incorrect OTP. Please try again.");
        } else if (data.status === 409) {
          toast.error("OTP Expired. Please request a new OTP.");
        } else {
          toast.error("An unexpected error occurred.");
        }
      } else {
        toast.error("Failed to verify OTP. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred while verifying OTP.");
      setIsVerifyEmail(false);
    }
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <Triangle
            height="120"
            width="120"
            color="blue"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
      <div className="md:flex-1 bg-cover bg-center relative fade-in-left create-ac-bg hideLeft">
        <div className="absolute inset-0 bg-black opacity-40"></div>
        <div className="absolute text-left md:m-20">
          <Link to="/">
            <img
              src="assets/images/main-logo.svg"
              alt="Logo"
              className="h-10 z-10"
            />
          </Link>
        </div>
        <div className="absolute inset-0 flex flex-col items-start justify-start md:px-20 px-5 mt-[200px]">
          <h3 className="text-white txt-style-1 mb-5">
            Work For charity? <br />
            Sign in for a Charity Account?
          </h3>
          <h3 className="text-white txt-style-2 mb-10">Create Account</h3>
        </div>
      </div>

      <div className="md:flex-1 flex items-center justify-start p-10 sm:p-10 marginInlineStart md:ms-10 max-w-screen-lg mx-auto w-full h-full fade-in-right">
        <div className="max-w-full w-full sm:max-w-lg 2xl:max-w-2xl relative">
          <div className="absolute top-0 right-0  p-4 hide-lg">
            <Link to="/">
              <img
                src="assets/images/main-logo.svg"
                alt="Logo"
                className="h-10 sm:mx-0"
              />
            </Link>
          </div>

          <h1 className="txt-style-3 text-left mt-16">
            Thousands Are Raising Funds Online On Prabalta You Can Too!
          </h1>

          <form onSubmit={handleSubmit(onSubmitHandler)}>
            {userType === "Organization" && (
              <div className="mb-6">
                <input
                  {...register("organizationName")}
                  placeholder="Enter Organization Name"
                  type="text"
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.organizationName && "border-red-500"
                  }`}
                />
                <p className="text-red-500 text-sm mt-1">
                  {errors.organizationName?.message}
                </p>
              </div>
            )}
            <div className="mb-6">
              <input
                {...register("username")}
                placeholder="Enter Your Name"
                type="text"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.username && "border-red-500"
                }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.username?.message}
              </p>
            </div>
            {/* <div className="mb-6 relative">
              <input
                {...register("email")}
                placeholder="Email_ID"
                type="email"
                autoComplete="new-password"
                disabled={isVerifyEmail}
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.email && "border-red-500"
                }`}
              />
              {isVerifyEmail ? (
                <FaCheckCircle
                  className="absolute top-1/2 right-10 transform -translate-y-1/2 text-green-500 cursor-pointer"
                  size={20}
                />
              ) : (
                <FaPaperPlane
                  title="Send otp"
                  className="absolute top-1/2 right-3 transform -translate-y-1/2 text-blue-500 cursor-pointer"
                  size={20}
                  onClick={otpFunction} // Call otpFunction when the send icon is clicked
                />
              )}
              <p className="text-red-500 text-sm mt-1">
                {errors.email?.message}
              </p>
            </div> */}
            <div className="mb-6 relative">
              <input
                {...register("email")}
                placeholder="Email_ID"
                type="email"
                autoComplete="new-password"
                disabled={isVerifyEmail}
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.email && "border-red-500"
                }`}
                onBlur={handleBlur} // Detect when input loses focus
              />
              {isVerifyEmail ? (
                <FaCheckCircle
                  className="absolute top-1/2 right-10 transform -translate-y-1/2 text-green-500 cursor-pointer"
                  size={20}
                />
              ) : null}
              <p className="text-red-500 text-sm mt-1">
                {errors.email?.message}
              </p>

              {showOtpButton && !isVerifyEmail && (
                <div className="flex  justify-end mt-2">
                  <button
                    className="flex items-center text-blue-500 cursor-pointer"
                    onClick={(event) => otpFunction(event)}
                  >
                    <FaPaperPlane size={20} className="mr-2" />
                    Send OTP
                  </button>
                </div>
              )}
            </div>

            {showOtp && (
              <>
                <div className=" flex gap-7 mt-4 ">
                  <PinInput
                    length={4} // Number of PIN boxes
                    value={pin}
                    onChange={handleChange}
                    type="number" // Use "number" for numeric input
                    placeholder="•" // Placeholder character for empty fields
                    styles={(theme) => ({
                      input: {
                        height: 40, // Custom height for each PIN box
                        width: 40, // Custom width for each PIN box
                        fontSize: 20, // Custom font size for the placeholder
                        textAlign: "center", // Center the text within each box
                        margin: "0 5px 0 0", // Space between boxes
                      },
                    })}
                  />

                  {showRefresh && (
                    <IoIosRefreshCircle
                      className="text-blue-500 cursor-pointer"
                      size={40}
                      onClick={otpFunction}
                    />
                  )}
                </div>
                <p className="text-red-500 text-sm mt-2 ">
                  {timer > 0
                    ? `Time remaining: ${Math.floor(timer / 60)}:${String(
                        timer % 60
                      ).padStart(2, "0")}`
                    : "Time expired"}
                </p>
              </>
            )}
            <div className="mb-6 relative">
              <input
                {...register("password")}
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.password && "border-red-500"
                }`}
              />
              <span
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer h-10"
              >
                {showPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <path
                      d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                      stroke="#000000"
                      strokeWidth="0.72"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                      stroke="#000000"
                      strokeWidth="0.72"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <path
                      d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5"
                      stroke="#000000"
                      strokeWidth="0.72"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </span>
              <p className="text-red-500 text-sm mt-1">
                {errors.password?.message}
              </p>
            </div>
            <div className="mb-6 relative">
              <input
                {...register("confirmPassword")}
                placeholder="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.confirmPassword && "border-red-500"
                }`}
              />
              <span
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer h-10"
              >
                {showConfirmPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <path
                      d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                      stroke="#000000"
                      strokeWidth="0.72"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                      stroke="#000000"
                      strokeWidth="0.72"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <path
                      d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5"
                      stroke="#000000"
                      strokeWidth="0.72"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </span>
              <p className="text-red-500 text-sm mt-1">
                {errors.confirmPassword?.message}
              </p>
            </div>
            <div className="mb-6">
              <Controller
                name="contactnumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <CustomInput
                    {...field}
                    mask="(999) 999-9999"
                    placeholder="Contact Number *"
                    alwaysShowMask={false}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.contactnumber && "border-red-500"
                    }`}
                  />
                )}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.contactnumber?.message}
              </p>
            </div>
            <div className="mb-6">
              <input
                {...register("termsAndConditions")}
                type="checkbox"
                className="mr-2"
              />
              <label className="text-dark-200 ">
                I have read and accept the{" "}
                <a onClick={() => setShowTermsPopup(true)}>
                  {" "}
                  <u>terms and conditions</u>
                </a>
              </label>
              <p className="text-red-500 text-sm mt-1">
                {errors.termsAndConditions?.message}
              </p>
            </div>
            {showTermsPopup && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="bg-white p-6 rounded-md max-w-md w-full">
                  <h2 className="text-xl font-bold mb-4">
                    Terms and Conditions
                  </h2>
                  <p>
                    By registering with our non-profit organization, you agree
                    to abide by our mission, values, and policies. You
                    understand that any misuse of resources or violation of
                    ethical standards may result in termination of your
                    registration.
                  </p>
                  <div className="mt-4">
                    <button
                      onClick={() => setShowTermsPopup(false)}
                      className="mt-6 p-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 cursor-pointer"
                    >
                      Accept
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="md:flex w-full justify-between items-center space-y-4 md:space-y-0">
              <button
                type="submit"
                className="w-full md:w-1/3 text-white p-2 rounded-md btn-style-1"
              >
                Sign Up
              </button>

              <p className="text-sm text-dark-600 text-center">
                <i> Or Sign Up With </i>
              </p>

              <div className="flex justify-center space-x-4">
                <a
                  href="#"
                  onClick={() => handleSocialIconClick(googleOAuthUrl)}
                  className="text-white p-2 rounded-full border w-8 h-8 flex items-center justify-center hover:shadow-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 488 512"
                  >
                    <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
                  </svg>
                </a>

                <a
                  href="#"
                  onClick={() => handleSocialIconClick(facebookOAuthUrl)}
                  className="text-white p-2 rounded-full border w-8 h-8 flex items-center justify-center hover:shadow-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 320 512"
                  >
                    <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                  </svg>
                </a>

                {/* <a
                  href="#"
                  onClick={() =>
                    handleSocialIconClick(
                      microsoftOAuthUrl
                    )
                  }
                  className="text-white p-2 rounded-full border w-8 h-8 flex items-center justify-center hover:shadow-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                  >
                    <path d="M0 93.7l183.6-25.3v177.4H0V93.7zm0 324.6l183.6 25.3V268.4H0v149.9zm203.8 28L448 480V268.4H203.8v177.9zm0-380.6v180.1H448V32L203.8 65.7z" />
                  </svg>
                </a> */}
              </div>
            </div>
          </form>

          <ToastContainer theme="colored" />
        </div>
      </div>
    </div>
  );
};

export default NgoCreateAccount;
