import React, { useState, useEffect } from "react";
import TopHeader from "../Home/TopHeader";
import SocialMediaSideBar from "./SocialMediaSideBar";
import { Link } from "react-router-dom";
import axios from "axios";
import SocialMediaTour from "./SocialMediaTour";
import { TQ_URL } from "../../utility/baseUrl";
import { FaEllipsisV } from "react-icons/fa";
import {
  getRequestWithTokenWithData,
  getRequestWithTokenWithDataWithAuth,
} from "../../utility/apiRequest"; // Adjust the import path accordingly
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import { Triangle } from "react-loader-spinner";

const ConnectedChannels = () => {
  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);

  const tourCompletedFlag = localStorage.getItem("socialTourCompletedFlag");

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const partnerId = partnerData.partnerId;

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  // This useEffect hook will handle screen resizing
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1200);
    };

    // Call the resize handler initially to set the correct value
    handleResize();

    // Listen for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const partnerData = JSON.parse(localStorage.getItem("partnerData"));
        // const url = `https://prabalta.org:8087/prabaltatwouat/connected-channels/${partnerData.partnerId}`;
        const url = `https://prabalta.org:8087/prabaltatwo/connected-channels/${partnerData.partnerId}`;
        //  const url = `http://localhost:8080/connected-channels/${partnerData.partnerId}`;
        const data = await getRequestWithTokenWithDataWithAuth(url);
        setChannels(data);
      } catch (error) {
        console.error("Error fetching connected channels:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchChannels();
  }, []);

  const handleEdit = (channelId) => {
    console.log(`Editing channel with ID: ${channelId}`);
  };

  const handleDelete = (channelId) => {
    console.log(`Deleting channel with ID: ${channelId}`);
  };

  const getLogoUrl = (channelName) => {
    switch (channelName.toLowerCase()) {
      case "facebook":
        return "/assets/images/facebook.png";
      case "twitter":
        return "/assets/images/twitter.png";
      case "instagram":
        return "/assets/images/instagram.png";
      default:
        return "/assets/images/default.png"; // Default logo if channel is not recognized
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Triangle
          visible={true}
          height="120"
          width="120"
          color="blue"
          ariaLabel="triangle-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-10">
        <DonorManagementTop />
      </div>
      <div className="flex mt-[50px]">
        <div className="md:w-64 w-1/12 absolute md:fixed">
          <SocialMediaSideBar />
        </div>

        <main className="w-full verify-user md:ml-[250px] md:pb-[100px]">
          <div className="mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6">
              <div className="text-center">
                <h2 className="text-2xl font-bold">Connected Channels</h2>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row items-center md:mt-4">
            <div className="md:mx-8 rounded p-1 flex md:w-1/2 w-full justify-between md:flex-grow-0 md:w-6/12">
              <h3 className="md:ml-[220px] ml-[100px] md:mt-[50px] text-gray">
                {channels.length} Channels connected.
              </h3>
            </div>
            <div id="connect_channel_new" className="flex mx-8 my-2">
              <Link to={`/socialMediaCampaign`}>
                <button className="btn-style-1 text-white py-1 px-1 md:py-2 md:px-4 md:ml-[-35px] rounded">
                  Connect Channels
                </button>
              </Link>
            </div>
          </div>

          {channels.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-full mt-10">
              <p className="text-xl font-bold">No channels are connected</p>
            </div>
          ) : (
            <div className="channel-list md:w-1/2 md:mx-[250px] md:mt-8">
              {channels.map((channel, index) => (
                <div key={index} className="channel-item flex justify-between">
                  <div className="flex md:mt-4">
                    {channel.pageImageUrl == null ||
                      channel.pageImageUrl === "" ? (
                      <img
                        src={
                          "https://social.prabalta.org/assets/images/user.webp"
                        }
                        alt="Placeholder Avatar"
                        className="rounded-full h-[3rem] w-[3rem]"
                      />
                    ) : (
                      <img
                        src={channel.pageImageUrl}
                        alt="User Avatar"
                        className="rounded-full h-[3rem] w-[3rem]"
                      />
                    )}
                    <img
                      src={getLogoUrl(channel.socialMediaConnector.channelName)}
                      alt={channel.pageName}
                      style={{
                        width: "25px",
                        height: "25px",
                        position: "relative",
                        top: "22px",
                        left: "-16px",
                      }}
                    />

                    <div className="ml-[50px] md:mb-4">
                      <span className="font-semibold text-gray-800">
                        {channel.pageName}
                      </span>
                      <span className="text-gray-500 text-sm block">
                        {channel.socialMediaConnector.channelName}
                      </span>
                    </div>
                  </div>

                  <div className="options">
                    <img
                      src="/assets/images/check.png"
                      alt={channel.pageName}
                      className="checkIcon"
                    />
                    {/* {activeIndex === index && (
                      <div
                        className="tooltip"
                        onMouseEnter={() => setActiveIndex(index)}
                        onMouseLeave={() => setActiveIndex(null)}
                      >
                        <button onClick={() => handleEdit(channel.id)}>
                          Disconnect Channel
                        </button>
                        <button onClick={() => handleDelete(channel.id)}>
                          Refresh Channel
                        </button>
                      </div>
                    )}
                    <FaEllipsisV
                      className="ellipsis-icon "
                      onClick={() =>
                        setActiveIndex(index === activeIndex ? null : index)
                      }
                    /> */}
                  </div>
                </div>
              ))}
            </div>
          )}
        </main>
      </div>
      {tourCompletedFlag === "false" && isLargeScreen && <SocialMediaTour />}
    </>
  );
};

export default ConnectedChannels;
