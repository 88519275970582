import React from "react";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {
  getRequestWithTokenWithData,
  getRequestWithTokenWithoutData,
} from "../../utility/apiRequest";
import { TQ_URL, TQ_URL_USPROJECT } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";

const ChangePassword = () => {
  // const [urlToken, setUrlToken] = useState[""];

  const urlParams = new URLSearchParams(window.location.search);
  
  const navigate = useNavigate();
  let extractedtoken = urlParams.get("tokenPwd");
  let partnerId = urlParams.get("partnerid"); 

if (extractedtoken && extractedtoken.endsWith("/")) {
  extractedtoken = extractedtoken.slice(0, -1); // Remove the trailing slash
}

if (partnerId && partnerId.endsWith("/")) {
  partnerId = partnerId.slice(0, -1); // Remove the trailing slash
}

  console.log(extractedtoken);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    watch,
  } = useForm({
    mode: "onChange", // Enable onChange mode to update the isValid flag
  });
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const [verifiedMsg, setVerifiedMsg] = useState("");

  const [captchaResponse, setCaptchaResponse] = useState(null);
  const [userType, setUserType] = useState("");

  const handleCaptchaResponseChange = (response) => {
    setCaptchaResponse(response);
    setVerifiedMsg("");
  };


  const newPassword = watch("newPassword", "");
  const confirmPassword = watch("confirmPassword", "");

  const handleShowPassword = (field) => {
    if (field === "newPassword") {
      setShowNewPassword(!showNewPassword);
    } else if (field === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  useEffect(() => {
    const userTypeCookie = Cookies.get("UserType");
    setUserType(userTypeCookie);
  }, []);

  const handleSave = async (data) => {
    console.log(data);

    const url = userType == "Individual" || partnerId == 1 ? `${TQ_URL_USPROJECT}/resetPassword` : `${TQ_URL}/resetPassword`
    if (data.newPassword != data.confirmPassword) {
      toast.error("Password and confirmPassword does not match. ", {
        position: "top-right",
      });
      return;
    } else {
      try {
        if (!captchaResponse) {
          setVerifiedMsg("Please complete the reCAPTCHA verification.");
          //  toast.error("Please complete the reCAPTCHA verification.");
          return;
        }

        setVerifiedMsg("");

        const response = await getRequestWithTokenWithData(
          url,
          {
            tokenURL: extractedtoken,
            password: data.newPassword,
          }
        );
        console.log(response);
        if (response.message == "Invalid token.") {
          toast.error("Invalid Token!", {
            position: "top-right",
          });
          setTimeout(() => {
            navigate("/ngo-login");
          }, 1000);
        } else if (response.message == "Token expired.") {
          toast.error("Token expired.", {
            position: "top-right",
          });
          setTimeout(() => {
            navigate("/ngo-login");
          }, 1000);
        } else {
          toast.success("Passsword Updated SuccessFully!.", {
            position: "top-right",
          });
          setTimeout(() => {
            navigate("/ngo-login");
          }, 1000);
        }
      } catch (error) {
        console.error(
          "Error in fetching link for set new password.:",
          error.response.data.message
        );
        toast.error(error.response.data.message, {
          position: "top-right",
        });
        setTimeout(() => {
          navigate("/ngo-login");
        }, 1000);
      }
    }
  };

  return (
    <div className="flex min-h-screen">
      {/* Left Section */}
      <div className="change-pw-bg flex-1 bg-cover bg-center relative fade-in-left hidden md:block ">
        <div className="absolute inset-0 bg-black opacity-40"></div>
        <div className="absolute text-left m-20">
          <Link to="/">
            <img
              src="assets/images/main-logo.svg"
              alt="Logo"
              className="h-10 z-10"
            />
          </Link>
        </div>
        <div className="absolute inset-0 flex flex-col items-start justify-end px-20">
          <h3 className="txt-style-2 mb-5">Unlock Hope:</h3>
          <h3 className="txt-style-1 mb-16">
            Reset Your Password
            <br />& Reconnect with Our Mission
          </h3>
        </div>
      </div>

      {/* Right Section */}
      <div className="flex-1 flex items-center justify-center p-10 fade-in-right fade-in-right">
        <div className="max-w-lg w-full">
          <h1 className="txt-style-3 text-center">CHANGE PASSWORD</h1>
          {/* New Password and Confirm Password Fields */}
          <form onSubmit={handleSubmit(handleSave)}>
            <div className="mb-6 relative">
              <Controller
                control={control}
                name="newPassword"
                rules={{ required: "New Password is required" }}
                render={({ field }) => (
                  <div>
                    <input
                      {...field}
                      type={showNewPassword ? "text" : "password"}
                      id="newPassword"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pr-10 ${
                        errors.newPassword && "border-red-500"
                      }`}
                      placeholder="New Password"
                      required
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                      <button
                        type="button"
                        onClick={() => handleShowPassword("newPassword")}
                        className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer h-10"
                      >
                        {showNewPassword ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 24 24"
                            fill="none"
                            className="text-gray-400 hover:text-gray-500"
                          >
                            <path
                              d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                              stroke="#000000"
                              strokeWidth="0.72"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                              stroke="#000000"
                              strokeWidth="0.72"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 24 24"
                            fill="none"
                            className="text-gray-400 hover:text-gray-500"
                          >
                            <path
                              d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5"
                              stroke="#000000"
                              strokeWidth="0.72"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </button>
                    </div>
                  </div>
                )}
              />
              {errors.newPassword && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.newPassword.message}
                </p>
              )}
            </div>
            <div className="mb-6 relative">
              <Controller
                control={control}
                name="confirmPassword"
                rules={{ required: "Confirm Password is required" }}
                render={({ field }) => (
                  <div>
                    <input
                      {...field}
                      type={showConfirmPassword ? "text" : "password"}
                      id="confirmPassword"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pr-10 ${
                        errors.confirmPassword && "border-red-500"
                      }`}
                      placeholder="Confirm Password"
                      required
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                      <button
                        type="button"
                        onClick={() => handleShowPassword("confirmPassword")}
                        className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer h-10"
                      >
                        {showConfirmPassword ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 24 24"
                            fill="none"
                            className="text-gray-400 hover:text-gray-500"
                          >
                            <path
                              d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                              stroke="#000000"
                              strokeWidth="0.72"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                              stroke="#000000"
                              strokeWidth="0.72"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 24 24"
                            fill="none"
                            className="text-gray-400 hover:text-gray-500"
                          >
                            <path
                              d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5"
                              stroke="#000000"
                              strokeWidth="0.72"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </button>
                    </div>
                  </div>
                )}
              />
              {errors.confirmPassword && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.confirmPassword.message}
                </p>
              )}
              {confirmPassword !== "" && newPassword !== confirmPassword && (
                <p className="text-red-500 text-sm mt-1">
                  Passwords do not match
                </p>
              )}
            </div>



            <ReCAPTCHA
              sitekey="6LeaQ4QpAAAAACwPHgef7yz2B3tgaNqtNwsevXi9"
              onChange={handleCaptchaResponseChange}
            />
            <span className="text-red-500 text-sm mt-1">{verifiedMsg}</span>
         


            {/* Save Button */}
            <div className="flex justify-center mt-10">
              {/* <Link to="/login"> */}
              <button
                type="submit"
                className={`w-full text-white p-2 px-12 rounded-md btn-style-1 ${
                  (!isValid || !isDirty || newPassword !== confirmPassword) &&
                  "opacity-50 cursor-not-allowed"
                }`}
                disabled={
                  !isValid || !isDirty || newPassword !== confirmPassword
                }
              >
                Save
              </button>
              {/* </Link> */}
            </div>
          </form>
          <ToastContainer theme="colored" />
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
