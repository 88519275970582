import * as React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Ensure you import the styles

import { getRequestWithTokenWithData } from "../../utility/apiRequest"; // Assuming you have these utility functions
import { TQ_URL } from "../../utility/baseUrl";
import { TQ_URL_USPROJECT } from "../../utility/baseUrl";
import fetchConfig from "../../config"; // Import the fetchConfig function

// const stripePromise = loadStripe(
//   "pk_test_51PAyezLe3p6lyB5zxgX9dWr1cKnaCQaLeMuszH89MHOpdexYhLjNEYkCN1agqwFHB5hNisslZhEiA29wcQVqzctQ00SttfXyvP"
// );
const stripePromise = loadStripe(
  "pk_live_51PAyezLe3p6lyB5zhlWFUpI7xyQm6KbSaH3qKMeZHcB9FUt9TiETLKyuKFwAAL59S4i6fXYXPN9Ux007WoTsvVJT00foz3nV3e"
);
const StripePayments = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [config, setConfig] = useState(null); // State to store config data

  const { state } = useLocation();
  const navigate = useNavigate();

  const fundraiserId = state?.fundraiserId;
  const fundraiserName = state?.fundraiserName;
  const amount = state?.amount;
  const email = state?.email;
  const phone = state?.phone;
  const fullName = state?.fullName;
  const anonymously = state?.anonymously;

  console.log("selected fundraiserId:", fundraiserId);
  console.log("selected fundraiserName:", fundraiserName);
  console.log("Amount:", amount);

  // Function to create checkout session
  const createCheckoutSession = async (partnerId) => {
    try {
      const response = await getRequestWithTokenWithData(
        `${TQ_URL_USPROJECT}/create-checkout-session`,
        {
          fundraiserId: fundraiserId,
          fundraiserName: fundraiserName,
          amount: amount,
          email: email,
          phone: phone,
          fullName: fullName,
          anonymously: anonymously,
          partnerId: partnerId, // Add partnerId to the request data
        }
      );
      setClientSecret(response.clientSecret);
    } catch (error) {
      console.error("Error creating checkout session:", error);
      toast.error("Something went wrong");
      setTimeout(() => {
        navigate(`/fundraiser/${fundraiserId}`);
      }, 1500);
    }
  };

  // Fetch config on component mount and initiate checkout session
  useEffect(() => {
    const fetchAndSetConfig = async () => {
      const configData = await fetchConfig();
      setConfig(configData);
    };

    fetchAndSetConfig();
  }, []);

  // Create checkout session once config is loaded
  useEffect(() => {
    if (config && !clientSecret) {
      createCheckoutSession(config.partnerId);
    }
  }, [config, clientSecret]); // Add config and clientSecret as dependencies

  const options = { clientSecret };

  return (
    <>
      <div id="checkout">
        {clientSecret && (
          <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
      <ToastContainer theme="colored" />
    </>
  );
};

export default StripePayments;
